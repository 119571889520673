<template>
  <div class="home page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-pencil</v-icon>

      التميز لمده شهر
    </h2>
    <hr class="my-5 header-divider"/>


    <div class="pa-4 white">
      <v-progress-linear
          v-if="AdvertisingRatesListFormLoading"
          color="primary"
          indeterminate
          rounded
          height="6"
      ></v-progress-linear>
      <div v-else>
        <div class="textFields">

          <input type="hidden" name="id" v-model="AdvertisingRatesListForm.id" value="month">

          <div hidden>
          <v-text-field outlined dense
                        :rules="rules"
                        v-model="AdvertisingRatesListForm.name_ar"
                        counter="100"
                        label="التميز لمده شهر"
          ></v-text-field>
          </div>

          <div hidden>
          <v-text-field outlined dense
                        :rules="rules"
                        v-model="AdvertisingRatesListForm.name_en"
                        counter="100"
                        label="Excellence for one month"
          ></v-text-field>
          </div>


          <div hidden>
          <v-text-field outlined dense
                        :rules="rules"
                        v-model="AdvertisingRatesListForm.code"
                        counter="100"
          ></v-text-field>
          </div>



          <v-text-field outlined dense
                        :rules="rules"
                        v-model="AdvertisingRatesListForm.price"
                        label="أدخل السعر"
          ></v-text-field>



          <div hidden>
          <v-text-field outlined dense
                        :rules="rules"
                        v-model="AdvertisingRatesListForm.days"
                        counter="100"
          ></v-text-field>
          </div>



        </div>


        <!-- SEND BUTTON -->
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
              @click="saveAdvertisingRatesList(AdvertisingRatesListForm)"
              large
              color="primary"
          >حفظ
          </v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AdvertisingRatesList3Form",

  data: function () {
    return {
      AdvertisingRatesListForm: {
        id: "month",
      },
      errors: {},
      AdvertisingRatesListFormLoading: false,
      rules: [
        v => !!v || "هذا الحقل مطلوب",
      ],

    };
  },


  created() {
    this.fetchAdvertisingRatesList3Data()
  },
  methods: {


    saveAdvertisingRatesList(AdvertisingRatesListForm) {
      this.AdvertisingRatesListFormLoading = true;
      let formData = new FormData();

      // Append form data to FormData
      for (let key in AdvertisingRatesListForm) {
        formData.append(key, AdvertisingRatesListForm[key]);
      }

      formData.append('id', AdvertisingRatesListForm.id);

      this.axios
          .post("admin/advertising_rates_list/create", formData)
          .then((response) => {
            this.$bus.$emit("showSnackbar", {
              text: "تم حفظ السجل بنجاح",
              color: "success",
            });

          })


          .catch((error) => {


            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
            }
            for (let key in error.response.data.errors) {

              this.$bus.$emit("showSnackbar", {
                text: error.response.data.errors[key][0],
                color: "error",
              });
            }
          })
          .finally(() => {
            this.AdvertisingRatesListFormLoading = false;
          });
    },

    fetchAdvertisingRatesList3Data() {
      this.AdvertisingRatesListFormLoading = true;

      this.axios.get(`/admin/advertising_rates_list/${"month"}`)
          .then((response) => {
            console.log(response.data);
            this.$set(this.AdvertisingRatesListForm, 'name_ar', response.data.name_ar);
            this.$set(this.AdvertisingRatesListForm, 'name_en', response.data.name_en);
            this.$set(this.AdvertisingRatesListForm, 'code', response.data.code);
            this.$set(this.AdvertisingRatesListForm, 'price', response.data.price);
            this.$set(this.AdvertisingRatesListForm, 'days', response.data.days);
          })
          .catch((error) => {
            this.$bus.$emit("showSnackbar", {
              text: "There was a mistake when rendering the page.",
              color: "red darken-3",
            });
          })
          .finally(() => {
            this.AdvertisingRatesListFormLoading = false;
          });
    },
  },
};
</script>
<style scoped>
.textFields {
  margin-bottom: 40px;
}
</style>
