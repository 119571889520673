<template>
  <div class="root-Users" width="100%" height="200">
    <v-card flat max-width="100%" tile>
      <v-app-bar class="Users" height="150" color="#F9F9F9" flat>
         <h2 class="accent--text">
           <v-icon color="primary" class="ml-3" large>mdi-format-list-bulleted</v-icon>

           لائحة اسعار الاعلانات
    </h2>
        <back-button />
    <hr class="my-5 header-divider" />

      </v-app-bar>
    </v-card>

    <div class="table-home">

      <component v-if="showAdvertisingRatesList0" :is="target0component" />
      <component v-if="showAdvertisingRatesList1" :is="target1component" />
      <component v-if="showAdvertisingRatesList2" :is="target2component" />
      <component v-if="showAdvertisingRatesList3" :is="target3component" />

    </div>
  </div>
</template>

<script>
import AdvertisingRatesList0Form from "./Forms/AdvertisingRatesList0Form.vue";
import AdvertisingRatesList1Form from "./Forms/AdvertisingRatesList1Form.vue";
import AdvertisingRatesList2Form from "./Forms/AdvertisingRatesList2Form.vue";
import AdvertisingRatesList3Form from "./Forms/AdvertisingRatesList3Form.vue";

export default {
  name: "AdvertisingRatesLists",
  data() {
    return {
      target0component: "AdvertisingRatesList0Form",
      target1component: "AdvertisingRatesList1Form",
      target2component: "AdvertisingRatesList2Form",
      target3component: "AdvertisingRatesList3Form",
      showAdvertisingRatesList0: true,
      showAdvertisingRatesList1: true,
      showAdvertisingRatesList2: true,
      showAdvertisingRatesList3: true,
    };
  },
  components: {
    AdvertisingRatesList0Form,
    AdvertisingRatesList1Form,
    AdvertisingRatesList2Form,
    AdvertisingRatesList3Form,
  },
};
</script>
<style>
* {
  font-family: "Leelawadee";
}
.root-Users {
  margin: -20px;
}
.Users {
  padding-right: 40px;
  padding-left: 40px;
}
</style>
